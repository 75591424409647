import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card,CardContent, LinearProgress,ListItemSecondaryAction } from '@mui/material';
import GatewayDelete from './AgentDelete';
import { GetGateway } from '../services/ProvisionService';
import RouterIcon from '@mui/icons-material/Router';





 const GatewayList = ({newTenant}) =>  {
  const params = useParams();
    const [tenants,setTenants] = useState([]);
    const [tenantsLoading,setTenantsLoading] = useState(true);
    const [error,SetError] = useState('');
    const [dialogTenant, setDialogTenant] = useState('');
    const [dialogId, setDialogIds] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tenantDelete, setTenantDeleted] = useState({})


    useEffect(() => {

        GetGateway(params.tenantid,params.locationid).then(response => {
            setTenants(response.data)
            setTenantsLoading(false)
        }).catch(() => {
            SetError('failed to load tenants')
        })
    },[newTenant,tenantDelete]);




    const handleOpenDialog = (id,gateway) => {
        setDialogTenant(gateway);
        setDialogIds(id)
        setIsDialogOpen(true);
        
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };

      const handleDeleted = (data) => {
        setTenantDeleted(data)
        handleCloseDialog();
      }
    

  return (
    <Card sx={{ minWidth: 275 }}>
    <CardContent>
          <Typography sx={{  mb: 2 }} variant="h6" component="div">
            All Gateways
          </Typography>
          {tenantsLoading ? 
          <LinearProgress /> :
          <List dense={true}>
          {tenants.map((item) => (
      <ListItem key={item.id}
            button
            component={Link} to={item.id + "/management"} 
      >
        
        <ListItemAvatar>
          <Avatar>
            <RouterIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.lastResported} />
        <ListItemSecondaryAction onClick={() => handleOpenDialog(item.id,item.name)}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
          </List>
        }
          </CardContent>
          <GatewayDelete open={isDialogOpen} onClose={handleCloseDialog} tenant={params.tenantid} location={params.locationid} agent={dialogTenant} id={dialogId} deleted={handleDeleted}></GatewayDelete>
          </Card>

  )
}

export default GatewayList