import SignIn from "../components/loginForm"

const login = () => {
    return(
        <div>
        <SignIn></SignIn>
        </div>
    )
}

export default login