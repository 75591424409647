import { ListItem,ListItemButton,ListItemIcon, ListItemText } from "@mui/material"
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useContext } from "react";
import { Context } from "../providers/contextProvider";

const useStyles = makeStyles({
    link: {
      textDecoration: 'none', // Remove the underline
      color: 'inherit', // Use the parent's color (default text color)
      '&:hover': {
        textDecoration: 'none', // Remove the underline on hover
        color: 'inherit', // Use the parent's color on hover
      },
    },
  });

const ProtectedMenuItem = ({authRoles = []}) => {

    const { roles } = useContext(Context);
    const matchingElements = roles.filter((item) => authRoles.includes(item));

    if(matchingElements.length < 1){
      return(<></>)
    }


    const classes = useStyles();
    return(
        <ListItem  component={Link} to="/globaladmin" disablePadding sx={{ display: 'block' } } className={classes.link} >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
        <SettingsApplicationsIcon/>
          </ListItemIcon>
          <ListItemText primary={'Global Admin'} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    )
}

export default ProtectedMenuItem