
import ApiSerivce from './ApiService';



export const CreateTenant = async (tenant, email) => {
    return await ApiSerivce().post('/tenant', { name: tenant, InitialUser: email, id: "null" }); 
};

export const DeleteTenant = async (tenant) => {
    return await ApiSerivce().delete('/tenant/' + tenant); 
};

export const GetTenants = async () => {
    return await ApiSerivce().get('/tenant');
};

export const GetTenantsMe = async () => {
    return await ApiSerivce().get('/tenant/me');
};

export const GetLocations = async (tenantid) => {
    return await ApiSerivce().get('/tenant/'+ tenantid + '/site');
};

export const CreateLocation = async (tenantid,name ) => {
    return await ApiSerivce().post('/tenant/' + tenantid + '/site', { name: name, id:"null" }); 
};