import React, { Children, useContext } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Context } from "../providers/contextProvider";


const ProtectedRoute = ({
  redirectPath = '/login',
  authRoles = [],
  paramBasedRoles = [],
  children,
}) => {

  const params = useParams();


  paramBasedRoles.forEach((param) => {

  
    const newRoleReader = params[param] + "_reader";
    const newRoleAdmin = params[param] + "_admin";
    
    if (!authRoles.includes(newRoleReader)) {
      authRoles.push(newRoleReader);
    }
    
    if (!authRoles.includes(newRoleAdmin)) {
      authRoles.push(newRoleAdmin);
    }

  });


  console.log(authRoles)


  const navigate = useNavigate();
  const { roles } = useContext(Context);

  if(authRoles.length >= 1){
    const matchingElements = roles.filter((item) => authRoles.includes(item));

    if(matchingElements.length < 1){
      navigate("/forbidden");
    }

  }

  var user = localStorage.getItem("token")
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;