import { useState } from "react";
import NewProvision from "../components/NewProvision"
import ProvisionList from "../components/ProvisionList";
import { Grid } from "@mui/material";


const Provisioning = () => {
    const [newProv,setNewProv] = useState({})
    return (
        <Grid container spacing={2}>
        <Grid item xs={8}>
        <ProvisionList newTenant={newProv}></ProvisionList>
        </Grid>
        <Grid item xs={4}>
        
        <NewProvision newTenantCreated={(data) => setNewProv(data)}></NewProvision>
        </Grid>

        </Grid>

    ) 
}

export default Provisioning;