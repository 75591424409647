import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteGateway } from '../services/ProvisionService';

const GatewayDelete = ({tenant, location, agent, id,  open, onClose, deleted}) => {

 const Delete = () => {
    DeleteGateway(tenant,location,id).then((response) => {
        deleted(response)
    }).catch((exception) => {
    
    })
 };
  return (
    <div>

      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deleting agent " + agent}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           You are about to delete gateway {agent}, if sure press delete otherwise press cancel
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button  color="secondary"  onClick={() => Delete()} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GatewayDelete;