import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import NewTenant from '../components/NewTenant';
import TenantList from '../components/TenantList';
import { Grid } from '@mui/material';



const globalAdmin = () => {

    const [newTenantCreated, setNewTenantCreated] = useState({})

    const handleNewTenantCreate = (data) => {
        setNewTenantCreated(data)
    }
    return(
        <>
        <Grid container spacing={2}>
        <Grid item xs={6}>
         <TenantList newTenant={newTenantCreated}></TenantList>
        </Grid>
        <Grid item xs={6}>
        
        <NewTenant newTenantCreated={handleNewTenantCreate}></NewTenant>
        </Grid>

        </Grid>
        </>
    )
}

export default globalAdmin;