import React ,{useState, useEffect}  from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CreateLocation } from '../services/TenantAndLocationService';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from  '@mui/material/LinearProgress'
import { Stack, TextField, Typography} from '@mui/material';

const LocationNew = ({}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [name,setName] = useState([]);
    const [close,setClose] = useState(true);
    const [error,setError] = useState('');
    


 
    const handleSubmit = (e)  => {
      e.preventDefault();
      CreateLocation(params.tenantid,name).then(response => {
          navigate("/tenant/" + params.tenantid + "/location/" + name)
      }).catch(exception => {
          setError('something when wrong when adding tenant')
      });
  
    };

  return (
    <div>

      <Dialog
        open={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
 
        <DialogTitle id="alert-dialog-title">
        {
            "New Location"
        }
        </DialogTitle>
        <DialogContent>
        <DialogContentText>
        <Stack
      component="form"
      sx={{
        width: '25ch',
      }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
      <TextField
    
        label="Location name"
        id="filled-hidden-label-small"
        variant="filled"
        value={name}
        onChange={(e) => setName(e.target.value)}
      
        size="small"
      />

<Button onClick={(e) => handleSubmit(e)} variant="contained">Save</Button>
<Typography style={{ color: 'red' }}  variant="caption">{error}</Typography>
    </Stack>

        </DialogContentText>
        </DialogContent>
        <DialogActions>


 

        </DialogActions>

      </Dialog>
    </div>
  );
}

export default LocationNew;