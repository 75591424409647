import React, { createContext, useState, useEffect } from 'react';
import { login } from '../services/AuthService';
import ApiSerivce from '../services/ApiService';

export const Context = createContext();

const ContextProvider = ({ children }) => {

    const [jsonData, setJsonData] = useState(null);
    const [roles, SetRoles] =  useState([]);

    useEffect(() => {
      const result = localStorage.getItem("roles")
      if(result){
        SetRoles(JSON.parse(result));
      }
      fetch('/config.json')
        .then((response) => response.json())
        .then((data) => {
          setJsonData(data);
          localStorage.setItem("backend", data.backendUrl)
        })
        .catch((error) => console.error('Error fetching JSON:', error));
    }, []);

    if (!jsonData) {
        return <div>Loading...</div>;
    }


    const HandleLogin = (username, password) => {
      return new Promise((resolve, reject) => {
        login(username, password)
          .then((result) => {
            if (result) {
              ApiSerivce()
                .get("/auth/roles")
                .then((response) => {
                  localStorage.setItem("roles",JSON.stringify(response.data));
                  SetRoles(response.data);
                  resolve(true);
                })
                .catch(() => {
                  alert("Failed to fetch roles. Please refresh the page.");
                  reject(false);
                });
            } else {
              reject(false);
            }
          })
          .catch(() => {
            reject(false);
          });
      });
    };
    

    
 

  return (
    <Context.Provider value={{ HandleLogin, roles  }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
