import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card,CardContent, LinearProgress,ListItemSecondaryAction } from '@mui/material';
import { GetProvision } from '../services/ProvisionService';
import TenantDelete from './TenantDelete';
import RouterIcon from '@mui/icons-material/Router';





 const ProvisionList = ({newTenant}) =>  {

    const params = useParams();

    const [tenants,setTenants] = useState([]);
    const [tenantsLoading,setTenantsLoading] = useState(true);
    const [error,SetError] = useState('');
    const [dialogTenant, setDialogTenant] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tenantDelete, setTenantDeleted] = useState({})


    const fetchTenants = () => {
      GetProvision(params.tenantid, params.locationid)
        .then((response) => {
          setTenants(response.data);
          setTenantsLoading(false);
        })
        .catch(() => {
          setError('Failed to load tenants');
        });
    };
  
    // Fetch tenants data on component mount and whenever newTenant or tenantDelete changes
    useEffect(() => {
      fetchTenants();
  
      // Set up the interval to refresh every 10 seconds
      const intervalId = setInterval(fetchTenants, 5000);
  
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }, [params.tenantid, params.locationid, newTenant, tenantDelete]);
  
    // Your JSX rendering logic here...


  
  
  
  


    const handleOpenDialog = (tenant) => {
        setDialogTenant(tenant);
        setIsDialogOpen(true);
        
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };

      const handleDeleted = (data) => {
        setTenantDeleted(data)
        handleCloseDialog();
      }
    

  return (
    <Card sx={{ minWidth: 275 }}>
    <CardContent>
          <Typography sx={{  mb: 2 }} variant="h6" component="div">
            Active Provisionings
          </Typography>
          {tenantsLoading ? 
          <LinearProgress /> :
          <List dense={true}>
          {tenants.map((item) => (
      <ListItem key={item.id}>
        
        <ListItemAvatar>
          <Avatar>
            <RouterIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.id} />
        <ListItemSecondaryAction onClick={() => handleOpenDialog(item.name)}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
          </List>
        }
          </CardContent>
          <TenantDelete open={isDialogOpen} onClose={handleCloseDialog} tenant={dialogTenant} deleted={handleDeleted}></TenantDelete>
          </Card>

  )
}

export default ProvisionList