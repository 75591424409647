import { Navigate } from "react-router-dom";

const SignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");

    return(
        <Navigate to={"/login"}></Navigate>
    )
}

export default SignOut;