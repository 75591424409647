import React, {useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card,CardContent, LinearProgress,ListItemSecondaryAction } from '@mui/material';
import { GetTenants } from '../services/TenantAndLocationService';
import TenantDelete from './TenantDelete';




 const TenantList = ({newTenant}) =>  {

    const [tenants,setTenants] = useState([]);
    const [tenantsLoading,setTenantsLoading] = useState(true);
    const [error,SetError] = useState('');
    const [dialogTenant, setDialogTenant] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tenantDelete, setTenantDeleted] = useState({})


    useEffect(() => {

        GetTenants().then(response => {
            setTenants(response.data)
            setTenantsLoading(false)
        }).catch(() => {
            SetError('failed to load tenants')
        })
    },[newTenant,tenantDelete]);




    const handleOpenDialog = (tenant) => {
        setDialogTenant(tenant);
        setIsDialogOpen(true);
        
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };

      const handleDeleted = (data) => {
        setTenantDeleted(data)
        handleCloseDialog();
      }
    

  return (
    <Card sx={{ minWidth: 275 }}>
    <CardContent>
          <Typography sx={{  mb: 2 }} variant="h6" component="div">
            All Tenants
          </Typography>
          {tenantsLoading ? 
          <LinearProgress /> :
          <List dense={true}>
          {tenants.map((item) => (
      <ListItem key={item.id}>
        
        <ListItemAvatar>
          <Avatar>
            <AccountBoxIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction onClick={() => handleOpenDialog(item.name)}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
          </List>
        }
          </CardContent>
          <TenantDelete open={isDialogOpen} onClose={handleCloseDialog} tenant={dialogTenant} deleted={handleDeleted}></TenantDelete>
          </Card>

  )
}

export default TenantList