import { useState } from "react"
import { useParams } from "react-router-dom";
import { Card,CardContent, Stack, TextField, Button, Typography } from "@mui/material"
import { CreateProvision } from "../services/ProvisionService";
import { red } from "@mui/material/colors";

const NewProvision = ({ newTenantCreated }) => {

   const params = useParams();

    const [mac, setMac] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
 
    const handleSubmit = (e)  => {
        e.preventDefault();
        CreateProvision(params.tenantid,params.locationid,name,description,mac).then(response => {
            newTenantCreated(response.data)
        }).catch(exception => {
            setError('something when wrong when adding tenant')
        });
    
      };

    return (
        <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Typography sx={{ mb: 2 }} variant="h6" component="div">
           Provision
          </Typography>
        <Stack
      component="form"
      sx={{
        width: '25ch',
      }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
      <TextField
    
        label="mac adress"
        id="mac"
        variant="filled"
        value={mac}
        onChange={(e) => setMac(e.target.value)}
        size="small"
      />




<TextField
    
    label="name"
    id="name"
    variant="filled"
    value={name}
    onChange={(e) => setName(e.target.value)}
    size="small"
  />
        <TextField
          id="standard-multiline-static"
          label="Description"
          multiline
          variant="filled"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={4}


        />
<Button onClick={(e) => handleSubmit(e)} variant="contained">Save</Button>
<Typography style={{ color: 'red' }}  variant="caption">{error}</Typography>
    </Stack>
        </CardContent>
        </Card>
    )
}

export default NewProvision