import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, DialogContent, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { GetIntergrationFlow, GetIntergrations, PostCOnfigurationFLow } from "../services/GatewayService";

const NewIntergrationFinisher = ({ open, onClose, tenant, location, gateway, intergration }) => {
    const [flows, setIntergrationFlow] = useState({});
    const [flowLoading, setFLowLoading] = useState(true);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        GetIntergrationFlow(tenant, location, gateway, intergration).then((result) => {
            setIntergrationFlow(result.data)
            setFLowLoading(false)
            console.log(result.data)
        })
    }, [open])

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleChange = (name,value) => {

        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        console.log(formData)
        PostCOnfigurationFLow(tenant, location, gateway, intergration, flows.flow_id, formData).then((result) => {
           if(result.data.type === "form"){
            setFormData({});
            setIntergrationFlow(result.data)
           }
           if(result.data.type === "create_entry"){
            setFormData({})
            onClose()
           }
        })
    }

    return (


        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Configure intergration {intergration}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormControl fullWidth>

                        {flowLoading ? <></> :

                            <>
                                {flows.data_schema.map((option) => {

                                    if (option.type === "float" || option.type === "string") {
                                    
                                        return (
                                            (
                                                <TextField

                                                    label={option.name}
                                                    name={option.name}
                                                    id="filled-hidden-label-small"
                                                    variant="filled"
                                                    onChange={handleFieldChange}
                                                    defaultValue={option.default}
                                                    value={formData[option.name] || ''}
                                                    size="small"
                                                />

                                            )
                                        )

                                    }

                                if(option.type === "select")
                                {
                          
                                    return(
                                        <>
                                        <FormControl>
                                        <InputLabel id={option.name}>{option.name}</InputLabel>
                                        <Select
                                        labelId={option.name}
                                        id={option.name}
                                        name={option.name}
                                        onChange={handleFieldChange}
                                        >
                                        {option.options.map((selectOptions) => (
                                            <MenuItem  key={selectOptions[0]} value={selectOptions[0]}>
                                            {selectOptions[1]}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                        </FormControl>
                                        </>
                                    )
                                }

                                })}
                            </>

                        }

                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => handleSubmit()} color="secondary" autoFocus>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewIntergrationFinisher;