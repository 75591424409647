import { Dialog,DialogTitle, DialogContentText,DialogActions,Button, DialogContent, FormControl,InputLabel,Select,MenuItem } from "@mui/material"
import { useEffect, useState } from "react";
import { GetIntergrations } from "../services/GatewayService";
import NewIntergrationFinisher from "./NewIntergrationFinisher";

const NewIntergrationSelector = ({open, onClose, tenant,location,gateway}) => {
    const [intergrations,setIntergrations] = useState([]);
    const [selectedIntergration, setSelectedIntergration] = useState();
    const [intergrationFinisherOpen, setIntergrationFinisherOpen] = useState(false);

    useEffect(() => {
        GetIntergrations(tenant,location,gateway).then((result) => {
                setIntergrations(result.data)
                setSelectedIntergration(result.data[0])
        })
    },[])

    const handleChange =(e) => {
        setSelectedIntergration(e.target.value) 
    }

    const handleSubmit = () => {
        onClose();
        setIntergrationFinisherOpen(true);


    }
    return(

    <>
        <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
    "Select a service / device what you want to add"
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <FormControl fullWidth>
            <InputLabel id="locaction-select-label">Select tenant</InputLabel>
            <Select
            labelId="locaction-select-label"
            id="location-select"
            
            defaultValue={selectedIntergration}
            onChange={(e) => handleChange(e)}
            >
            {intergrations.map((option) => (
                <MenuItem key={option} value={option}>
                {option}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}  color="secondary"   autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {intergrationFinisherOpen &&
      <NewIntergrationFinisher tenant={tenant} location={location} gateway={gateway} open={intergrationFinisherOpen} onClose={() => setIntergrationFinisherOpen(false)} intergration={selectedIntergration}></NewIntergrationFinisher>
      }
      
      </>
    )
}

export default NewIntergrationSelector;