import axios from 'axios';
import ApiSerivce from './ApiService';


const AuthService = () => axios.create({
  baseURL: localStorage.getItem("backend"),
  timeout: 10000, // You can adjust the timeout value as needed
});

export const login = async (username, password) => {
  try {
    const response = await AuthService().post('/auth', { username, password });
    localStorage.setItem("token",response.data.token);
    return true
  } catch (error) {
    return false
  }
};

export const getInvite = async (id) => {
   return await  ApiSerivce().get("/auth/invite/"+id);
}

export const completeInvite = async (id, password) => {
    return await  ApiSerivce().post("/auth/invite/"+id,{username: "invite", password: password});
 }


 export const getApiKey = async (id) => {
  return await  ApiSerivce().get("/auth/"+id+ "/apikey");
}
 export const newApiKey = async (tenant) => {
    return await  ApiSerivce().post("/auth/"+tenant+ "/apikey");
 }

 export const removeApiKey = async (tenant,apikey) => {
  return await  ApiSerivce().delete("/auth/"+tenant+ "/apikey/" + apikey);
}