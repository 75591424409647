import React ,{useState, useEffect}  from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteTenant, GetLocations } from '../services/TenantAndLocationService';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from  '@mui/material/LinearProgress'

const LocationSwitcher = ({tenantId}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [tenants,setTenants] = useState([]);
    const [tenantsLoading,setTenantsLoading] = useState(true);
    const [selectedTenant, setSelectedTenant] = useState('not_');
    const [error,SetError] = useState('');
    
    useEffect(() => {

        GetLocations(params.tenantid).then(response => {
            setTenants(response.data)
            var ammount = response.data.length
            console.log(response.data.length)
            setTenantsLoading(false)
            if(ammount === 1){
                navigate("/tenant/"+ tenants[0]/id)
            }
            if(ammount > 1){

                setSelectedTenant(tenants[0])
            }
            if(ammount < 1){
     
              navigate("/tenant/"+params.tenantid+"/firstlocation")
            }
      

            console.log(response.data)
        
        }).catch(() => {
            SetError('failed to load tenants')
        })
    },[]);

 const handleChange = (e) => {
    console.log(e.target.value)
    setSelectedTenant(e.target.value)
 };

 const selectTenant = () => {
    navigate("/tenant/"+ params.tenantid +"/location/" + selectedTenant )
 }
  return (
    <div>

      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
 
        <DialogTitle id="alert-dialog-title">
        {!tenantsLoading ? 
          "Wich location would you like to manage":
          "                                    "
        }
        </DialogTitle>
        <DialogContent>
        {tenantsLoading ? 
          <LinearProgress  sx={{ minWidth: 275 }}/> :
          <>
          <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Select tenant</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedTenant}
        defaultValue={selectedTenant}
        onChange={(e) => handleChange(e)}
      >
        {tenants.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
          </>
}
        </DialogContent>
        <DialogActions>

        {tenantsLoading ? 
          "":
          <Button  color="secondary"  onClick={() => selectTenant()} autoFocus>
          Go
        </Button>
        }
 

        </DialogActions>

      </Dialog>
    </div>
  );
}

export default LocationSwitcher;