import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/login'
import ContextProvider from './providers/contextProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './layout/Layout';
import GlobalAdmin from './pages/globalAdmin';
import Invite from './pages/invite';
import Forbidden from './pages/forbidden';
import TenantSwitcher from './components/TenantSwitcher';
import SignOut from './components/SignOut';
import LocationSwitcher from './components/LocationSwitcher';
import LocationFirst from './components/LocationFirst.js';
import LocationNew from './components/LocationNew';
import Provisioning from './pages/provisioning';
import GatewayList from './components/GatewayList';
import TenantSettings from './pages/tenantSettings';
import GatewayManagement from './pages/gatewayManagement';



const app = () => {

    return(
<ContextProvider>

        <Router>
        <Routes>
        <Route path="/" element={<ProtectedRoute authRoles={[]}><Layout><TenantSwitcher/></Layout></ProtectedRoute>}></Route>
          <Route path="/login" element={<Login />} />
          <Route path='/signout' element={<SignOut/>}/>
          <Route path='/invite/:id' element={<Invite></Invite>} />
          <Route path='/forbidden' element={<Forbidden></Forbidden>} />

          <Route path="/tenant/:tenantid/settings" element={<ProtectedRoute authRoles={["global_admin"]} paramBasedRoles={["tenantid"]}><Layout><TenantSettings></TenantSettings></Layout></ProtectedRoute>}></Route>

          <Route path="/globaladmin" element={<ProtectedRoute authRoles={["global_admin"]}><Layout><GlobalAdmin></GlobalAdmin></Layout></ProtectedRoute>}></Route>
          <Route path="/tenant/:tenantid/location/:locationid" element={<ProtectedRoute><Layout><div>test</div></Layout></ProtectedRoute>}></Route>
          <Route path="/tenant/:tenantid/location/:locationid/gateways" element={<ProtectedRoute><Layout><GatewayList></GatewayList></Layout></ProtectedRoute>}></Route>
          <Route path="/tenant/:tenantid/location/:locationid/gateways/:gatewayid/management" element={<ProtectedRoute><Layout><GatewayManagement></GatewayManagement></Layout></ProtectedRoute>}></Route>

          <Route path="/tenant/:tenantid/location/:locationid/provisioning" element={<ProtectedRoute><Layout><Provisioning/></Layout></ProtectedRoute>}></Route>
          <Route path="/tenant/:tenantid/location/:locationid/documentation" element={<ProtectedRoute><Layout><div>docs</div></Layout></ProtectedRoute>}></Route>
          <Route path="/tenant/:tenantid" element={<ProtectedRoute><Layout><LocationSwitcher></LocationSwitcher></Layout></ProtectedRoute>}></Route>
          <Route path="/tenant/:tenantid/firstlocation" element={<ProtectedRoute><Layout><LocationFirst></LocationFirst></Layout></ProtectedRoute>}></Route>
          <Route path="/tenant/:tenantid/newlocation" element={<ProtectedRoute><Layout><LocationNew></LocationNew></Layout></ProtectedRoute>}></Route>
        </Routes>
      </Router>
      
</ContextProvider>  )

}

export default app