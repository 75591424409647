import React ,{useState, useEffect}  from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteTenant, GetLocations } from '../services/TenantAndLocationService';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from  '@mui/material/LinearProgress'

const LocationFirst = ({}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [tenants,setTenants] = useState([]);
    const [tenantsLoading,setTenantsLoading] = useState(true);
    const [selectedTenant, setSelectedTenant] = useState('not_');
    const [error,SetError] = useState('');
    


 const handleChange = (e) => {
    console.log(e.target.value)
    setSelectedTenant(e.target.value)
 };

 const create = () => {
    navigate("/tenant/"+ params.tenantid + "/newlocation")
 }
  return (
    <div>

      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
 
        <DialogTitle id="alert-dialog-title">
        {
            "Create you first location"
        }
        </DialogTitle>
        <DialogContent>
        <DialogContentText>
            We see you have never configured a location for this tenant <br>
            </br><br></br>
            A location is a physical location (for example if you manage mulitple hotels/parks) you can add muliple locations to define fine grained permissions later on.
            <br></br><br></br> If you manage muliple single home location it is easier to provision the gateways in a single location 


        </DialogContentText>
        </DialogContent>
        <DialogActions>

        <Button  color="secondary"  onClick={() => create()} autoFocus>
          Create Location
        </Button>
 

        </DialogActions>

      </Dialog>
    </div>
  );
}

export default LocationFirst;