import { useState } from "react"
import { Card,CardContent, Stack, TextField, Button, Typography } from "@mui/material"
import { CreateTenant } from "../services/TenantAndLocationService";
import { red } from "@mui/material/colors";

const NewTenant = ({ newTenantCreated }) => {
    const [tenant, setTenant] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
 
    const handleSubmit = (e)  => {
        e.preventDefault();
        CreateTenant(tenant,email).then(response => {
            newTenantCreated(response.data)
        }).catch(exception => {
            setError('something when wrong when adding tenant')
        });
    
      };

    return (
        <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Typography sx={{ mb: 2 }} variant="h6" component="div">
            New Tenant
          </Typography>
        <Stack
      component="form"
      sx={{
        width: '25ch',
      }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
      <TextField
    
        label="Tenant Name"
        id="filled-hidden-label-small"
        variant="filled"
        value={tenant}
        onChange={(e) => setTenant(e.target.value)}
        size="small"
      />

<TextField
    
    label="Email"
    id="filled-hidden-label-small"
    variant="filled"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    size="small"
  />
<Button onClick={(e) => handleSubmit(e)} variant="contained">Save</Button>
<Typography style={{ color: 'red' }}  variant="caption">{error}</Typography>
    </Stack>
        </CardContent>
        </Card>
    )
}

export default NewTenant