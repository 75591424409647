
import ApiSerivce from './ApiService';



export const GetZones = async (tenant,location,id) => {
    return await ApiSerivce().get('/tenant/' + tenant + '/site/' + location + '/agent/'+ id + "/zones"); 
};

export const GetConfiguration = async (tenant,location,id) => {
    return await ApiSerivce().get('/tenant/' + tenant + '/site/' + location + '/agent/'+ id + "/configuration"); 
};


export const GetIntergrations = async (tenant,location,id) => {
    return await ApiSerivce().get('/tenant/' + tenant + '/site/' + location + '/agent/'+ id + "/intergration"); 
};

export const GetIntergrationFlow = async (tenant,location,id,intergration) => {
    return await ApiSerivce().get('/tenant/' + tenant + '/site/' + location + '/agent/'+ id + "/intergration/"+ intergration + "/flow"); 
};

export const PostCOnfigurationFLow = async (tenant,location,id,intergration,flowid,body) => {
    return await ApiSerivce().post('/tenant/' + tenant + '/site/' + location + '/agent/'+ id + "/intergration/"+ intergration + "/flow/" + flowid,body); 
};