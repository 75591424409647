
import ApiKeys from "../components/ApiKeys"
import { Grid } from "@mui/material"

const TenantSettings = () => {
    return(
        <Grid container spacing={2}>

     <Grid xs={4}>
        <ApiKeys></ApiKeys>
        </Grid>
        </Grid>
    )
}

export default TenantSettings;