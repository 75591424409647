import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import {Switch, FormGroup, FormControlLabel, FormControl, FormLabel,LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { GetConfiguration, GetZones } from '../services/GatewayService';
import { json, useParams } from 'react-router-dom';
import ReactJson from 'react-json-view'
import NewIntergrationSelector from '../components/NewIntergrationSelector';


const GatewayManagement = () => {
    const params = useParams();

    const tenant = params.tenantid;
    const location = params.locationid;
    const gateway = params.gatewayid;

    const [zones,setZones] = useState([]);
    const [zonesLoading,setZonesLoading] = useState(true);
    const [configuration,setConfiguration] = useState({});
    const [configurationLoading,setConfigurationLoading] = useState(true);
    const [addDevicesOpen, setAddDevicesOpen] = useState(false);

    useEffect(() => {
        GetZones(tenant,location,gateway).then((result) => {
            setZones(result.data);
            setZonesLoading(false);

        }).catch((exception) => {

        });


        GetConfiguration(tenant,location,gateway).then((result) => {
            setConfiguration(result.data);
            setConfigurationLoading(false);

        }).catch((exception) => {

        });


    },[])


    return(

        <Grid container spacing={2}>
        <Grid item xs={3}>

        <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            Meterkast
          </Typography>
          <br></br>
          <Divider></Divider>
          <FormControl component="fieldset" variant="standard">

      <FormLabel component="legend">Turn On/Off</FormLabel>


          <FormGroup>
            <FormControlLabel  control={<Switch />}  label="Switches" />
            <FormControlLabel  control={<Switch />}  label="Lights" />
            </FormGroup>
            </FormControl>
            <Divider></Divider>
            <br>
            </br>
            <br></br>
<Typography>
    Total power usage: 500kwh
</Typography>
<Typography>
    Total power production: 50kwh
</Typography>

        </CardContent>
        <CardActions>
          <Button onClick={() => setAddDevicesOpen(true)}  size="small">Add Device</Button>
        </CardActions>
      </Card>
      </Grid>

      <Grid item xs={3}>

<Card sx={{ minWidth: 275 }}>
<CardContent>
<Typography gutterBottom variant="h5" component="div">
    Zones
  </Typography>
  <br></br>
  <Divider></Divider>
  {zones.map((item) => (

<FormControl component="fieldset" variant="standard">

<FormLabel component="legend">{item.name}</FormLabel>


  <FormGroup>
    <FormControlLabel  control={<Switch />}  label="Switches" />
    <FormControlLabel  control={<Switch />}  label="Lights" />
    </FormGroup>
    </FormControl>

  ))}
 
    <Divider></Divider>
    <br>
    </br>
    <br></br>


</CardContent>
<CardActions>
  <Button  size="small">Learn More</Button>
</CardActions>
</Card>
</Grid>



<Grid item xs={3}>

<Card sx={{ minWidth: 275 }}>
<CardContent>
<Typography gutterBottom variant="h5" component="div">
   Admin Details
  </Typography>
  <br></br>
  <Divider></Divider>
 {configurationLoading ? 
    <LinearProgress>

    </LinearProgress> :
    <>
    <ReactJson collapsed={true} src={configuration}></ReactJson>

    
    </>}
 
    <Divider></Divider>
    <br>
    </br>
    <br></br>


</CardContent>
<CardActions>
  <Button  size="small">Learn More</Button>
</CardActions>
</Card>
</Grid>

<NewIntergrationSelector tenant={tenant} location={location} gateway={gateway} open={addDevicesOpen} onClose={() => setAddDevicesOpen(false)}></NewIntergrationSelector>

      </Grid>

    )
}

export default GatewayManagement;