
import ApiSerivce from './ApiService';



export const CreateProvision = async (tenant,location,name,description,mac) => {
    return await ApiSerivce().post('/tenant/' + tenant + '/site/' + location + '/provision', { name: name, description: description,macAdress: mac, site: location, id: "null" }); 
};

export const GetProvision = async (tenant,location) => {
    return await ApiSerivce().get('/tenant/' + tenant + '/site/' + location + '/provision'); 
};

export const GetGateway = async (tenant,location) => {
    return await ApiSerivce().get('/tenant/' + tenant + '/site/' + location + '/agent'); 
};

export const DeleteGateway = async (tenant,location, id) => {
    return await ApiSerivce().delete('/tenant/' + tenant + '/site/' + location + '/agent/' + id); 
};

