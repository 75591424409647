import axios from "axios";

const ApiSerivce = () => {
    const instance = axios.create({
      baseURL: localStorage.getItem('backend'),
      timeout: 10000, // You can adjust the timeout value as needed
    });
  
    // Add an interceptor to set the Bearer token before each request
    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  
    return instance;
  };

  export default ApiSerivce;
