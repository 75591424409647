import { List,ListItem,ListItemButton,ListItemIcon,ListItemText,Divider } from "@mui/material"
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useParams } from "react-router-dom";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

import ProtectedMenuItem from "../components/ProtectedMenuItem";


import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import BackupIcon from '@mui/icons-material/Backup';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LogoutIcon from '@mui/icons-material/Logout';

const useStyles = makeStyles({
    link: {
      textDecoration: 'none', // Remove the underline
      color: 'inherit', // Use the parent's color (default text color)
      '&:hover': {
        textDecoration: 'none', // Remove the underline on hover
        color: 'inherit', // Use the parent's color on hover
      },
    },
  });

const MenuItems = () => {
    const params = useParams();
    var tenantRoute = "/"
    var locationRoute = "/"


    if(params.tenantid){
        tenantRoute = "/tenant/" + params.tenantid 
        localStorage.setItem("LastTenantRoute", tenantRoute);

        if(params.locationid){
            locationRoute = tenantRoute + "/location/" + params.locationid
            localStorage.setItem("LastLocationRoute",locationRoute);
        }
        else{
            var result = localStorage.getItem("LastLocationRoute");
            if(result){
                locationRoute = result;
            }
        }
    }else{
        var result = localStorage.getItem("LastTenantRoute");
        if(result){
            tenantRoute = result;
        }
    }

    console.log(tenantRoute);

    

    const classes = useStyles();
    return(
        <>
        <List >
          <ListItem  component={Link} to={locationRoute}  disablePadding sx={{ display: 'block' }} className={classes.link} >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DashboardIcon/>
              </ListItemIcon>
              <ListItemText primary={'Dashboard'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem  component={Link} to={locationRoute + "/gateways"}  disablePadding sx={{ display: 'block' }} className={classes.link} >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <BatchPredictionIcon/>
              </ListItemIcon>
              <ListItemText primary={'Gateways'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem  component={Link} to={locationRoute + "/provisioning"}   disablePadding sx={{ display: 'block' }} className={classes.link} >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <BackupIcon/>
              </ListItemIcon>
              <ListItemText primary={'Provisioning'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem  component={Link} to={locationRoute + "/documentation"}  disablePadding sx={{ display: 'block' }} className={classes.link} >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <FindInPageIcon/>
              </ListItemIcon>
              <ListItemText primary={'Documentation'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
      </List>

      <Divider />

      <List>
      <ListItem  component={Link} to={tenantRoute + "/settings"}  disablePadding sx={{ display: 'block' }} className={classes.link} >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DisplaySettingsIcon/>
              </ListItemIcon>
              <ListItemText primary={'Tenant Settings'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
      </List>

      <Divider />
      <List>
                <ProtectedMenuItem authRoles={["global_admin"]}></ProtectedMenuItem>
      </List>
      <Divider />
      <List>
      <ListItem  component={Link} to="/signout"  disablePadding sx={{ display: 'block' }} className={classes.link} >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LogoutIcon/>
              </ListItemIcon>
              <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
      </List>
      </>
    )
}
export default MenuItems;