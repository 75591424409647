import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card,CardContent, LinearProgress,ListItemSecondaryAction, Box, TextField, Divider, Stack, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText  } from '@mui/material';
import GatewayDelete from './AgentDelete';
import { GetGateway } from '../services/ProvisionService';
import { getApiKey, newApiKey } from '../services/AuthService';
import KeyIcon from '@mui/icons-material/Key';
import ApiKeyDelete from './ApiKeyDelete';





 const ApiKeys = ({newTenant}) =>  {
  const params = useParams();
    const [tenants,setTenants] = useState([]);
    const [tenantsLoading,setTenantsLoading] = useState(true);
    const [error,SetError] = useState('');
    const [dialogTenant, setDialogTenant] = useState('');
    const [dialogId, setDialogIds] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tenantDelete, setTenantDeleted] = useState({})
    const [apiKey,setApiKey] = useState({});
    const [showApiKey,setShowApiKey] = useState(false)


    useEffect(() => {

        getApiKey(params.tenantid).then(response => {
            setTenants(response.data)
            setTenantsLoading(false)
        }).catch(() => {
            SetError('failed to load tenants')
        })
    },[apiKey,tenantDelete]);




    const handleOpenDialog = (id) => {
        setDialogIds(id)
        setIsDialogOpen(true);
        
      };

      const handleNewKey = () => {
        newApiKey(params.tenantid).then((response) => {
            setApiKey(response.data)
            setShowApiKey(true)

        })
      }
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };

      const handleDeleted = (data) => {
        setTenantDeleted(data)
        handleCloseDialog();
      }
    

  return (
    <Card sx={{ minWidth: 275 }}>
    <CardContent>
          <Typography sx={{  mb: 2 }} variant="h6" component="div">
            Api Keys
          </Typography>
          {tenantsLoading ? 
          <LinearProgress /> :
          <List dense={true}>
          {tenants.map((item) => (
      <ListItem key={item.id}>
        
        <ListItemAvatar>
          <Avatar>
          <KeyIcon /> 
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.lastResported} />
        <ListItemSecondaryAction onClick={() => handleOpenDialog(item.keyId)}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
          </List>
        }
          </CardContent>
          <ApiKeyDelete open={isDialogOpen} apiKey={dialogId}  onClose={handleCloseDialog} tenant={params.tenantid} deleted={handleDeleted}></ApiKeyDelete>
          
          <Divider></Divider>
          <Box 
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '15ch' },
      }}
      noValidate
      autoComplete="off"
    >
              

      <Button onClick={() => handleNewKey()} variant='contained' size='small' >New Key</Button>
 
    </Box>

    <Dialog
        open={showApiKey}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
 
        <DialogTitle id="alert-dialog-title">
        {
            "Generated API Key"
        }
        </DialogTitle>
        <DialogContent>
        <DialogContentText>
            Please save this api key, it is not possible to view it later on. <br>
            </br><br></br>
          <b>{apiKey}</b>


        </DialogContentText>
        </DialogContent>
        <DialogActions>

        <Button  color="secondary" onClick={() => setShowApiKey(false)}   autoFocus>
          close
        </Button>
 

        </DialogActions>

      </Dialog>
         
          </Card>

  )
}

export default ApiKeys