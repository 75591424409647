import SignIn from "../components/loginForm"
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getInvite, completeInvite } from "../services/AuthService";
import { Container,Box,Typography,TextField,Button,Grid,Link, FormControlLabel,Checkbox, LinearProgress } from "@mui/material";

const Invite = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [invite,setInvite] = useState({});
    const [inviteLoading,setInviteLoading] = useState(true);
    const [password,setPassword] = useState('');
    
    useEffect(() => {

       getInvite(params.id).then((response) => {
        setInvite(response.data);
        setInviteLoading(false)
       })
    },[]);

    const handleSubmit = (e)  => {
        e.preventDefault();
        completeInvite(invite.id,password).then(() => {
            navigate("/login")
        })
        
    
      };
    return(
        <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {inviteLoading ? 
          <LinearProgress /> :
          <>
           <Typography component="h1" variant="h5">
            Complete account for {invite.tenant}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}

            />
    
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Complete
            </Button>
            </Box>
          </>
        }
         
          
    
        </Box>
      </Container>
    )
}

export default Invite;