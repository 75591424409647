import SignIn from "../components/loginForm"
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getInvite, completeInvite } from "../services/AuthService";
import { Container,Box,Typography,TextField,Button,Grid,Link, FormControlLabel,Checkbox, LinearProgress } from "@mui/material";

const Forbidden = () => {
    const navigate = useNavigate();
  
    


    const handleSubmit = (e)  => {
        e.preventDefault();
       navigate("/login")
    
      };
    return(
        <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

           <Typography component="h1" variant="h5">
            Forbidden, you can sign in with another account to access those resources
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>


            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login page
            </Button>
            </Box>

         
          
    
        </Box>
      </Container>
    )
}

export default Forbidden;